<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- transfer salesman -->
            <a-form-item
                label="Pilih Salesman"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="from_salesman"
                has-feedback>
                <FilterATSalesmanSalesman
                    style="width:100%"
                    placeholder="Pilih Salesman"
                    :mode="null"
                    :disabled="item.readOnly"
                    is_total_toko
                    :vendor_id="item.vendor_id"
                    v-model:value="form.from_salesman"/>
            </a-form-item>

            <a-row
                v-if="!item.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">Transfer</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="state.errors">
            <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                type="error"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" />
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import FilterATSalesmanSalesman from '@/components/filter/FilterATSalesmanSalesman'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterATSalesmanSalesman,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            from_salesman: null,
            readOnly: false,
        }))

        const formRef = ref()

        const state = reactive({
            title:'Transfer Toko Dari Salesman',
            endpoint: {
                current: `/api/users-salesman/customers/${props.item.id}/transfer`,
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            isTransferSalesman: false,
            rules: {
                from_salesman: [
                    {
                        required: true,
                        message: "Pilih salesman tidak boleh kosong!",
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {
            state.errors = []
            
            // validation form
            await formRef.value.validate()
                .catch(() => {})

            form.post(state.endpoint.current)
                .then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    message.success('Berhasil menyimpan')

                    handleModalCancel()
                    emit('success', data)
                })
                .catch(({ response: { status, statusText, data } = {} }) => {
                    if (status === 422) {
                        state.errors.push(...data.message.map(item => ({ message: item, status: data.status })))
                    } else {
                        errorMessage.value = `Kode error ${status}, ${data.message}`
                    }
                })


        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only roles variable
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss'
</style>
